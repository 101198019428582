<template>
  <type-page :config="appList" />
</template>

<script>
import TypePage from '@/components/datainfo/TypePage.vue'

import {
  appList
} from '@/data_conf/app'

export default {
  components: {
    TypePage
  },
  data () {
    return {
      appList: appList({
        openConf: {
          position: 'left',
          isPop: false
        }
      })
    }
  }
}
</script>
